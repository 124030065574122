"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TransmuteCollection = require("./TransmuteCollection");
var _immutable = require("immutable");
_TransmuteCollection.count.implement(Array, arr => arr.length);
_TransmuteCollection.count.implementInherited(_immutable.Iterable, subject => subject.count());
_TransmuteCollection.count.implement(Object, obj => Object.keys(obj).length);
_TransmuteCollection.count.implement(String, str => str.length);
_TransmuteCollection.count.implement(Number, num => num);
var _default = exports.default = _TransmuteCollection.count;
module.exports = exports.default;