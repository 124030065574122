"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _setIn;
var _count2 = _interopRequireDefault(require("./_count"));
var _has2 = _interopRequireDefault(require("./_has"));
var _get2 = _interopRequireDefault(require("./_get"));
var _keyedEquivalent2 = _interopRequireDefault(require("./_keyedEquivalent"));
var _reduce2 = _interopRequireDefault(require("./_reduce"));
var _set2 = _interopRequireDefault(require("./_set"));
function makeSetStack(keyPath, subject) {
  return (0, _reduce2.default)([], (acc, key) => {
    if (!acc.length) {
      acc.push([subject, key]);
      return acc;
    }
    const [prevValue, prevKey] = acc[acc.length - 1];
    const actualValue = (0, _get2.default)(prevKey, prevValue);
    const nextValue = actualValue === undefined && !(0, _has2.default)(prevKey, prevValue) ? (0, _keyedEquivalent2.default)(prevValue) : actualValue;
    acc.push([nextValue, key]);
    return acc;
  }, keyPath);
}
function _setIn(keyPath, value, subject) {
  if ((0, _count2.default)(keyPath) === 0) {
    return value;
  }
  const setStack = makeSetStack(keyPath, subject);
  let result = value;
  while (setStack.length > 0) {
    const [layer, key] = setStack.pop();
    result = (0, _set2.default)(key, result, layer);
  }
  return result;
}
module.exports = exports.default;