"use strict";
'use es6';

/**
 * Returns `true` if `subject` is `undefined`.
 *
 * @param  {any}  subject
 * @return {boolean}
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isUndefined;
function isUndefined(subject) {
  return subject === undefined;
}
module.exports = exports.default;