"use strict";
'use es6';

/**
 * Returns true if `value` is a Function.
 * 
 * @param {any} value
 * @return {boolean}
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isFunction;
function isFunction(value) {
  return typeof value === 'function';
}
module.exports = exports.default;