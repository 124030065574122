"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _makeSymbol;
var _uniqueId = _interopRequireDefault(require("../uniqueId"));
function _makeSymbol(name) {
  return typeof Symbol === 'function' ? Symbol(name) : (0, _uniqueId.default)(name);
}
module.exports = exports.default;