"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _get2 = _interopRequireDefault(require("./internal/_get"));
var _set2 = _interopRequireDefault(require("./internal/_set"));
var _curry = _interopRequireDefault(require("./curry"));
function update(key, updater, subject) {
  const value = (0, _get2.default)(key, subject);
  return (0, _set2.default)(key, updater(value), subject);
}

/**
 * Sets the value at `key` to the result of `updater`.
 *
 * @example
 * const incrementCount = update('count', n => n + 1);
 * incrementCount({count: 1});
 * // returns {count: 2}
 *
 * @param {any} key
 * @param {Function} updater
 * @param {Array|Iterable|Object} subject
 * @return {Array|Iterable|Object}
 */
var _default = exports.default = (0, _curry.default)(update);
module.exports = exports.default;