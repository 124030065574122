"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
_TransmuteCollection.reduce.implement(Array, (into, operation, arr) => {
  return arr.reduce(operation, into);
});
_TransmuteCollection.reduce.implement(Object, (into, operation, obj) => {
  const keys = Object.keys(obj);
  const len = keys.length;
  let acc = into;
  for (let i = 0; i < len; i++) {
    const key = keys[i];
    acc = operation(acc, obj[key], key, obj);
  }
  return acc;
});
_TransmuteCollection.reduce.implementInherited(_immutable.Iterable, (into, operation, iter) => {
  return iter.reduce(operation, into);
});
var _default = exports.default = _TransmuteCollection.reduce;
module.exports = exports.default;