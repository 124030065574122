"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _enforceFunction2 = _interopRequireDefault(require("./internal/_enforceFunction"));
var _enforceInterval2 = _interopRequireDefault(require("./internal/_enforceInterval"));
function debounce(interval, operation) {
  (0, _enforceInterval2.default)(interval);
  (0, _enforceFunction2.default)(operation);
  let lastArgs;
  let lastResult;
  let timer = null;
  function cancel() {
    clearTimeout(timer);
    timer = null;
  }
  function runner() {
    cancel();
    lastResult = operation(...lastArgs);
  }
  function debounced(...args) {
    lastArgs = args;
    cancel();
    timer = setTimeout(runner, interval);
    return lastResult;
  }
  debounced.cancel = cancel;
  return debounced;
}

/**
 * `operation` is called `interval` milliseconds after the most recent call.
 *
 * @param  {number} interval of milliseconds
 * @param  {Function} operation
 * @return {any} the most recent result of `operation`
 */
var _default = exports.default = (0, _curry.default)(debounce);
module.exports = exports.default;