"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = curry;
var _curryN = _interopRequireDefault(require("./curryN"));
/**
 * Creates a curried version of `operation`.
 *
 * @example
 * const toArray = curry((a, b, c) => [a, b, c]);
 * const toArrayWith1 = toArray(1);
 * toArrayWith1(2, 3) === [1, 2, 3];
 *
 * @param  {Function} operation
 * @return {Function}
 */
function curry(operation) {
  return (0, _curryN.default)(operation.length, operation);
}
module.exports = exports.default;