"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _some2 = _interopRequireDefault(require("./internal/_some"));
/**
 * Returns `true` if **any** items in `subject` match `predicate`.
 *
 * @example
 * const anyBlue = some(v => v === 'blue');
 *
 * anyBlue(List.of('blue', 'red')) === true;
 * anyBlue(List.of('red', 'red')) === true;
 *
 * @param  {Function} predicate returns `true` if item is a match.
 * @param  {Iterable} subject
 * @return {bool}
 */
var _default = exports.default = (0, _curry.default)(_some2.default);
module.exports = exports.default;