"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _immutable = require("immutable");
var _filter2 = _interopRequireDefault(require("./internal/_filter"));
function omit(keys, subject) {
  const keySet = _immutable.Seq.Set(keys);
  return (0, _filter2.default)((value, key) => !keySet.contains(key), subject);
}

/**
 * Drop specified keys from a KeyedIterable (e.g. a `Map` or `OrderedMap`).
 *
 * @example
 * // returns Map { "two" => 2 }
 * omit(
 *   ['one', 'three'],
 *   Map({one: 1, two: 2, three: 3})
 * );
 *
 * @param  {Array<any>} keys to remove.
 * @param  {KeyedIterable} subject from which to remove `keys`.
 * @return {KeyedIterable} without `keys`.
 */
var _default = exports.default = (0, _curry.default)(omit);
module.exports = exports.default;