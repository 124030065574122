"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = enforceArity;
function enforceArity(arity) {
  if (arity >= 0 && arity <= 9) {
    return arity;
  }
  throw new Error(`expected \`arity\` from 0 to 9 but got \`${arity}\``);
}
module.exports = exports.default;