"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setIn2 = _interopRequireDefault(require("./internal/_setIn"));
var _curry = _interopRequireDefault(require("./curry"));
/**
 * Set the `value` at `keyPath` in a nested structure.
 *
 * @example
 * setIn(['one', 'two'], 3, {one: {two: 2}});
 * // returns {one: {two: 3}}
 *
 * @example <caption>Unset keyPaths will be set based on the most recent type.</caption>
 * setIn(['one', 'two'], 3, {});
 * // returns {one: {two: 3}}
 *
 * setIn(['one', 'two'], 3, Map());
 * // returns Map { one => Map { two => 3 } }
 *
 * @param {Array<any>|Iterable<any>} keyPath
 * @param {any} value
 * @param {Array|Iterable|Object} subject
 * @param {Array|Iterable|Object}
 */
var _default = exports.default = (0, _curry.default)(_setIn2.default);
module.exports = exports.default;