"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _getIn;
var _get2 = _interopRequireDefault(require("./_get"));
function _getIn(keyPath, subject) {
  let value = subject;
  for (let i = 0; i < keyPath.length; i++) {
    if (value === undefined) {
      break;
    }
    value = (0, _get2.default)(keyPath[i], value);
  }
  return value;
}
module.exports = exports.default;