"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getIn2 = _interopRequireDefault(require("./internal/_getIn"));
var _curry = _interopRequireDefault(require("./curry"));
/**
 * Retrieve a `keyPath` from a nested Immutable or JS structure.
 *
 * `getIn` short circuts when it encounters a `null` or `undefined` value.
 *
 * @example
 * const getFirstName = getIn(['name', 'first']);
 * const user = UserRecord({
 *   name: Map({
 *     first: 'Test',
 *     last: 'Testerson',
 *   }),
 * });
 * getFirstName(user) === 'Test'
 *
 * @param  {Array<string>} keyPath
 * @param  {Array|Iterable|Object} subject
 * @return {any}
 */
var _default = exports.default = (0, _curry.default)(_getIn2.default);
module.exports = exports.default;