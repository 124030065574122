"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeUniqueId = makeUniqueId;
function makeUniqueId(prefix = '') {
  let lastId = 0;
  return () => `${prefix}-${++lastId}`;
}