"use strict";
'use es6';

/**
 * Returns the value converted to a string.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = toString;
function toString(value) {
  return String(value);
}
module.exports = exports.default;