"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
_TransmuteCollection.map.implement(Array, (mapper, arr) => arr.map(mapper));
_TransmuteCollection.map.implementInherited(_immutable.Iterable, (mapper, iter) => iter.map(mapper));
_TransmuteCollection.map.implement(Object, (mapper, obj) => {
  const result = {};
  const keys = Object.keys(obj);
  const len = keys.length;
  for (let i = 0; i < len; i++) {
    const key = keys[i];
    result[key] = mapper(obj[key], key, obj);
  }
  return result;
});
var _default = exports.default = _TransmuteCollection.map;
module.exports = exports.default;