"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _identity = _interopRequireDefault(require("./identity"));
var _ifElse = _interopRequireDefault(require("./ifElse"));
const ifElseOp = _ifElse.default.operation;
function ifThen(predicate, affirmative, subject) {
  return ifElseOp(predicate, affirmative, _identity.default, subject);
}

/**
 * Applies `affirmative` to `subject` if `predicate(subject)` is truthy.
 * Otherwise returns `subject`.
 *
 * @example
 * import ifThen from 'transmute/ifThen';
 *
 * const toJS = ifThen(
 *   subject => typeof subject.toJS === 'function',
 *   subject => subject.toJS
 * );
 *
 * toJS(List.of(1, 2, 3)) //=> [1, 2, 3]
 * toJS([1, 2, 3]) //=> [1, 2, 3]
 *
 * @param {Function} predicate
 * @param {Function} affirmative
 * @param {any} subject
 * @return {any}
 */
var _default = exports.default = (0, _curry.default)(ifThen);
module.exports = exports.default;