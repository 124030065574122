"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
_TransmuteCollection.set.implement(Array, (index, value, arr) => {
  if ((0, _immutable.is)(arr[index], value)) {
    return arr;
  }
  const next = arr.concat();
  next.splice(index, 1, value);
  return next;
});
_TransmuteCollection.set.implementInherited(_immutable.Iterable, (key, value, subject) => {
  return subject.set(key, value);
});
_TransmuteCollection.set.implement(Object, (key, value, obj) => {
  if ((0, _immutable.is)(obj[key], value)) {
    return obj;
  }
  const result = Object.assign({}, obj);
  result[key] = value;
  return result;
});
var _default = exports.default = _TransmuteCollection.set;
module.exports = exports.default;