"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
const makeObject = () => ({});
_TransmuteCollection.keyedEquivalent.implement(Array, makeObject);
_TransmuteCollection.keyedEquivalent.implement(Object, makeObject);
_TransmuteCollection.keyedEquivalent.implementInherited(_immutable.Collection, () => (0, _immutable.Map)());
_TransmuteCollection.keyedEquivalent.implementInherited(_immutable.Seq, () => _immutable.Seq.Keyed());
var _default = exports.default = _TransmuteCollection.keyedEquivalent;
module.exports = exports.default;