"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
const jsToValueSeq = subject => (0, _immutable.Seq)(subject).valueSeq();
_TransmuteCollection.valueSeq.implement(Array, jsToValueSeq);
_TransmuteCollection.valueSeq.implementInherited(_immutable.Iterable, subject => subject.valueSeq());
_TransmuteCollection.valueSeq.implement(Object, jsToValueSeq);
var _default = exports.default = _TransmuteCollection.valueSeq;
module.exports = exports.default;