"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
_TransmuteCollection.filter.implement(Array, (test, arr) => arr.filter(test));
_TransmuteCollection.filter.implementInherited(_immutable.Iterable, (test, iter) => iter.filter(test));
_TransmuteCollection.filter.implement(Object, (test, obj) => {
  const result = {};
  const keys = Object.keys(obj);
  const len = keys.length;
  for (let i = 0; i < len; i++) {
    const key = keys[i];
    const val = obj[key];
    if (test(val, key, obj)) {
      result[key] = val;
    }
  }
  return result;
});
_TransmuteCollection.filter.implementInherited(_immutable.Record, (test, rec) => {
  return rec.reduce((acc, val, key) => {
    if (!test(val, key, rec)) {
      return acc;
    }
    return acc.set(key, val);
  }, (0, _immutable.Map)());
});
var _default = exports.default = _TransmuteCollection.filter;
module.exports = exports.default;