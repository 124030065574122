"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
_TransmuteCollection.some.implement(Array, (predicate, arr) => {
  return arr.some(predicate);
});
_TransmuteCollection.some.implementInherited(_immutable.Iterable, (test, iter) => iter.some(test));
_TransmuteCollection.some.implement(Object, (predicate, obj) => {
  const keys = Object.keys(obj);
  const len = keys.length;
  for (let i = 0; i < len; i++) {
    const key = keys[i];
    if (predicate(obj[key], key, obj)) {
      return true;
    }
  }
  return false;
});
var _default = exports.default = _TransmuteCollection.some;
module.exports = exports.default;