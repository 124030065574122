"use strict";
'use es6';

/**
 * Returns it's first argument.
 *
 * @example
 * identity('something') === 'something'
 *
 * @param {any}  thing
 * @return {any}
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = identity;
function identity(thing) {
  return thing;
}
module.exports = exports.default;