"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _set2 = _interopRequireDefault(require("./internal/_set"));
var _curry = _interopRequireDefault(require("./curry"));
/**
 * Returns a copy of `subject` with `key` set to `value`.
 *
 * @example
 * set('one', 2, {one: 1});
 * // returns {one: 2}
 *
 * @param {any} key
 * @param {any} value
 * @param {Array|Iterable|Object} subject
 * @return {Array|Iterable|Object}
 */
var _default = exports.default = (0, _curry.default)(_set2.default);
module.exports = exports.default;