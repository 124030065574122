"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
const jsToKeySeq = subject => (0, _immutable.Seq)(subject).keySeq();
_TransmuteCollection.keySeq.implement(Array, jsToKeySeq);
_TransmuteCollection.keySeq.implementInherited(_immutable.Iterable, subject => subject.keySeq());
_TransmuteCollection.keySeq.implement(Object, jsToKeySeq);
var _default = exports.default = _TransmuteCollection.keySeq;
module.exports = exports.default;