"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TransmuteCollection = require("./TransmuteCollection");
var _immutable = require("immutable");
const empty = () => undefined;
_TransmuteCollection.get.implement(null, empty);
_TransmuteCollection.get.implement(undefined, empty);
_TransmuteCollection.get.implementInherited(_immutable.Iterable, (key, subject) => subject.get(key));
var _default = exports.default = _TransmuteCollection.get;
module.exports = exports.default;