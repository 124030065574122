"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _every2 = _interopRequireDefault(require("./internal/_every"));
/**
 * Returns `true` if **all** items in `subject` match `predicate`.
 *
 * @example
 * const alwaysBlue = every(v => v === 'blue');
 *
 * alwaysBlue(List.of('blue', 'blue')) === true;
 * alwaysBlue(List.of('red', 'blue')) === false;
 *
 * @param  {Function} predicate returns `true` if item is a match.
 * @param  {Iterable} subject
 * @return {bool}
 */
var _default = exports.default = (0, _curry.default)(_every2.default);
module.exports = exports.default;