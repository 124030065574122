"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _getIn2 = _interopRequireDefault(require("./internal/_getIn"));
var _setIn2 = _interopRequireDefault(require("./internal/_setIn"));
function updateIn(keyPath, updater, subject) {
  const value = (0, _getIn2.default)(keyPath, subject);
  return (0, _setIn2.default)(keyPath, updater(value), subject);
}

/**
 * Apply `updater` to the value at `keyPath`.
 *
 * @example
 * const incrementUserCount = updateIn(['users', 'count'], n => n + 1);
 * incrementUserCount({users: {count: 1}});
 * // returns {users: {count: 2}}
 *
 * @example <caption>Unset keyPaths will be set based on the most recent type.</caption>
 * const incrementUserCount = updateIn(['users', 'count'], (n = 0) => n + 1);
 * incrementUserCount({});
 * // returns {users: {count: 1}}
 *
 * incrementUserCount(Map());
 * // returns Map { users => Map { count => 1 } }
 *
 * @param  {Array<any>|Iterable<any>} keyPath the location where `updater` should be applied.
 * @param  {Function} updater the tranformation to apply.
 * @param  {Array|Iterable|Object} subject the thing to update.
 * @return {Array|Iterable|Object}
 */
var _default = exports.default = (0, _curry.default)(updateIn);
module.exports = exports.default;