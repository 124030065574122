"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enforceArity2 = _interopRequireDefault(require("./internal/_enforceArity"));
var _enforceFunction2 = _interopRequireDefault(require("./internal/_enforceFunction"));
var _setArity2 = _interopRequireDefault(require("./internal/_setArity"));
/* eslint no-use-before-define: 0 */
function bindWithArity(operation, arity, args) {
  const curried = (0, _setArity2.default)(arity, curryInternal.bind(null, operation, arity, args));
  curried.args = args;
  curried.operation = operation;
  return curried;
}
function curryInternal(operation, arity, prevArgs, ...nextArgs) {
  const remainingArity = arity - nextArgs.length;
  const args = prevArgs.concat(nextArgs);
  if (remainingArity <= 0) {
    return operation(...args);
  }
  return bindWithArity(operation, remainingArity, args);
}
function curryN(arity, operation) {
  return bindWithArity((0, _enforceFunction2.default)(operation), (0, _enforceArity2.default)(arity), []);
}

/**
 * Create a curried version of `operation` that expects `arity` arguments.
 * Inception-ally, `curryN` is also curried.
 *
 * @example
 * const toArray = curryN(3)((...args) => [...args]);
 * toArray(1, 2, 3) === [1, 2, 3];
 *
 * @param  {number} arity number of arguments the curried function accepts
 * @param  {Function} operation to curry
 * @return {Function}
 */
var _default = exports.default = curryN(2, curryN);
module.exports = exports.default;