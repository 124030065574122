"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = pipe;
var _enforceFunction2 = _interopRequireDefault(require("./internal/_enforceFunction"));
function piped(operations, arg) {
  let result = arg;
  for (let i = 0; i < operations.length; i++) {
    result = operations[i](result);
  }
  return result;
}

/**
 * Create a function that runs operations from left-to-right.
 *
 * `pipe` is _not_ curried.
 *
 * @example
 * const takeEvensAndDouble = pipe(
 *   filter(n => n % 2 === 0),
 *   map(n => n * 2)
 * );
 *
 * takeEvensAndDouble(List.of(1, 2, 3))
 * // returns List [ 4 ]
 *
 * @param  {Array<Function>} operations any number of unary functions.
 * @return {Function}
 */
function pipe(...operations) {
  operations.forEach(_enforceFunction2.default);
  return piped.bind(null, operations);
}
module.exports = exports.default;