"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _get2 = _interopRequireDefault(require("./internal/_get"));
/**
 * Retrieve the value at `key` from `subject`.
 *
 * @example
 * // returns 1
 * get('one', Map({one: 1, two: 2, three: 3}))
 *
 * @param  {any} key to lookup in `subject`.
 * @param  {Iterable|Object} subject in which to look up `key`.
 * @return {any} the value at `key`.
 */
var _default = exports.default = (0, _curry.default)(_get2.default);
module.exports = exports.default;