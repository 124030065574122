"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.valueSeq = exports.sortBy = exports.some = exports.set = exports.reduce = exports.map = exports.keyedEquivalent = exports.keySeq = exports.has = exports.get = exports.forEach = exports.flattenN = exports.filter = exports.every = exports.entrySeq = exports.count = exports.concat = exports.clear = void 0;
var _always = _interopRequireDefault(require("../always"));
var _isFunction = _interopRequireDefault(require("../isFunction"));
var _isNumber = _interopRequireDefault(require("../isNumber"));
var _protocol = _interopRequireDefault(require("../protocol"));
const isAnyValue = (0, _always.default)(true);
const clear = exports.clear = (0, _protocol.default)({
  args: [_protocol.default.TYPE],
  name: 'clear'
});

/**
 * @private
 * Returns the number of values in `subject`.
 *
 * @param {TYPE} subject
 * @return {number}
 */
const count = exports.count = (0, _protocol.default)({
  args: [_protocol.default.TYPE],
  name: 'count'
});

/**
 * @private
 * Returns a concatenated `subject` and `update`.
 *
 * @param {any} update
 * @param {TYPE} subject
 * @return {number}
 */
const concat = exports.concat = (0, _protocol.default)({
  args: [isAnyValue,
  // update
  _protocol.default.TYPE],
  name: 'concat'
});

/**
 * @private
 * Returns a Seq of key,value tuples (in JS Array)
 *
 * @param {TYPE}
 * @return {Seq<[any, any]>}
 */
const entrySeq = exports.entrySeq = (0, _protocol.default)({
  args: [_protocol.default.TYPE],
  name: 'entrySeq'
});

/**
 * @private
 * Returns true if `predicate` returns `true` for _all_ items in `subject`.
 *
 * @param {Function} predicate
 * @param {TYPE} subject
 * @return {bool}
 */
const every = exports.every = (0, _protocol.default)({
  args: [_isFunction.default,
  // predicate
  _protocol.default.TYPE],
  name: 'every'
});

/**
 * @private
 * Returns a new value of items in `subject` for which `predicate` returns `true`.
 *
 * @param {Function} predicate
 * @param {TYPE} subject
 */
const filter = exports.filter = (0, _protocol.default)({
  args: [_isFunction.default,
  // predicate
  _protocol.default.TYPE],
  name: 'filter'
});
const flattenN = exports.flattenN = (0, _protocol.default)({
  args: [_isNumber.default,
  // depth
  _protocol.default.TYPE],
  name: 'flattenN'
});
const forEach = exports.forEach = (0, _protocol.default)({
  args: [_isFunction.default,
  // effect
  _protocol.default.TYPE],
  name: 'forEach'
});

/**
 * @private
 * Return the value of `key` in `subject`.
 *
 * @param {any} key
 * @param {TYPE} subject
 * @return {any}
 */
const get = exports.get = (0, _protocol.default)({
  args: [isAnyValue,
  // key
  _protocol.default.TYPE],
  name: 'get',
  fallback: (key, obj) => obj[key]
});

/**
 * @private
 * Returns `true` if `key` is in `subject`'s keys.
 *
 * @param {any} key
 * @param {TYPE} subject
 * @return {any}
 */
const has = exports.has = (0, _protocol.default)({
  args: [isAnyValue,
  // key
  _protocol.default.TYPE],
  name: 'has'
});

/**
 * @private
 * Return a `Seq` of the keys in `subject`.
 *
 * @param {TYPE<K, _>} subject
 * @return {Seq<K>}
 */
const keySeq = exports.keySeq = (0, _protocol.default)({
  args: [_protocol.default.TYPE],
  name: 'keySeq'
});
const keyedEquivalent = exports.keyedEquivalent = (0, _protocol.default)({
  args: [_protocol.default.TYPE],
  name: 'keyedEquivalent'
});

/**
 * @private
 * Returns a new value by applying `mapper` to each item in `subject`.
 *
 * @param {Function} mapper
 * @param {TYPE} subject
 * @return {TYPE}
 */
const map = exports.map = (0, _protocol.default)({
  args: [_isFunction.default,
  // mapper
  _protocol.default.TYPE],
  name: 'map'
});

/**
 * @private
 * Returns a new value by applying `mapper` to each item in `subject`.
 *
 * @param {Function} mapper
 * @param {TYPE} subject
 * @return {TYPE}
 */
const reduce = exports.reduce = (0, _protocol.default)({
  args: [isAnyValue,
  // accumulator
  _isFunction.default,
  // reducer
  _protocol.default.TYPE],
  name: 'reduce'
});

/**
 * @private
 * Set the `value` of `key` in `subject`.
 *
 * @param {any} value
 * @param {any} key
 * @param {TYPE} subject
 * @return {TYPE}
 */
const set = exports.set = (0, _protocol.default)({
  args: [isAnyValue,
  // value
  isAnyValue,
  // key
  _protocol.default.TYPE],
  name: 'set'
});

/**
 * @private
 * Returns true if `predicate` returns `true` for _any_ items in `subject`.
 *
 * @param {Function} predicate
 * @param {TYPE} subject
 * @return {bool}
 */
const some = exports.some = (0, _protocol.default)({
  args: [_isFunction.default,
  // predicate
  _protocol.default.TYPE],
  name: 'some'
});

/**
 * @private
 * Returns a copy of `subject` sorted according to `getSortValue`.
 *
 * @param {Function} getSortValue
 * @param {TYPE} subject
 * @return {TYPE}
 */
const sortBy = exports.sortBy = (0, _protocol.default)({
  args: [_isFunction.default,
  // getSortValue
  _protocol.default.TYPE],
  name: 'sortBy'
});

/**
 * @private
 * Return a `Seq` of the values in `subject`.
 *
 * @param {TYPE<_, V>} subject
 * @return {Seq<K>}
 */
const valueSeq = exports.valueSeq = (0, _protocol.default)({
  args: [_protocol.default.TYPE],
  name: 'valueSeq'
});