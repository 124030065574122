"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _count2 = _interopRequireDefault(require("./internal/_count"));
var _has2 = _interopRequireDefault(require("./internal/_has"));
var _curry = _interopRequireDefault(require("./curry"));
var _getIn = _interopRequireDefault(require("./getIn"));
const getInOp = _getIn.default.operation;
function hasIn(keyPath, subject) {
  const keyLen = (0, _count2.default)(keyPath);
  if (keyLen === 0) {
    return false;
  }
  const parent = getInOp(keyPath.slice(0, -1), subject);
  if (parent === undefined) {
    return false;
  }
  return (0, _has2.default)(keyPath[keyLen - 1], parent);
}

/**
 * Returns `true` if `keyPath` is defined in a nested data structure.
 *
 * `hasIn` short circuts and returns `false` when it encounters a `null` or `undefined` value.
 *
 * @example
 * const hasFirstName = hasIn(['name', 'first']);
 * const user = UserRecord({
 *   name: Map({
 *     first: 'Test',
 *     last: 'Testerson',
 *   }),
 * });
 * hasFirstName(user) === true
 *
 * @param  {Array<string>} keyPath
 * @param  {Array|Iterable|Object} subject
 * @return {boolean}
 */
var _default = exports.default = (0, _curry.default)(hasIn);
module.exports = exports.default;