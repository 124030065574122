"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = uniqueId;
let nextId = 0;

/**
 * Returns a unique integer string appended to `prefix`.
 *
 * @example
 * uniqueId('test-') === 'test-1';
 * uniqueId('other-') === 'other-2';
 * uniqueId('test-') === 'test-3';
 */
function uniqueId(prefix = '') {
  return `${prefix}${nextId++}`;
}
module.exports = exports.default;