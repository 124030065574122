"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isEmpty;
var _count2 = _interopRequireDefault(require("./internal/_count"));
/**
 * Returns true if `value` is "empty".
 * If given null, undefined, isEmpty will return true.
 *
 * @param {any} value
 * @return {boolean}
 */
function isEmpty(value) {
  if (!value) {
    return true;
  }
  return (0, _count2.default)(value) === 0;
}
module.exports = exports.default;