"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = enforceFunction;
function enforceFunction(operation, index) {
  if (typeof operation === 'function') {
    return operation;
  }
  const name = typeof index === 'number' ? `operation[${index}]` : 'operation';
  throw new Error(`expected \`${name}\` to be a function but got \`${String(operation)}\``);
}
module.exports = exports.default;