"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
_TransmuteCollection.flattenN.implementInherited(_immutable.Iterable, (depth, subject) => subject.flatten(depth));
var _default = exports.default = _TransmuteCollection.flattenN;
module.exports = exports.default;