"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
_TransmuteCollection.sortBy.implement(Array, (getSortValue, arr) => {
  return (0, _immutable.Seq)(arr).sortBy(getSortValue).toArray();
});
_TransmuteCollection.sortBy.implementInherited(_immutable.Iterable, (getSortValue, subject) => subject.sortBy(getSortValue));
_TransmuteCollection.sortBy.implement(Object, (getSortValue, obj) => (0, _immutable.Seq)(obj).sortBy(getSortValue).toObject());
var _default = exports.default = _TransmuteCollection.sortBy;
module.exports = exports.default;