"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _enforceFunction2 = _interopRequireDefault(require("./internal/_enforceFunction"));
var _enforceInterval2 = _interopRequireDefault(require("./internal/_enforceInterval"));
function throttle(interval, operation) {
  (0, _enforceInterval2.default)(interval);
  (0, _enforceFunction2.default)(operation);
  let lastArgs;
  let lastResult;
  let lastCall = 0;
  let timer = null;
  function cancel() {
    if (timer !== null) {
      clearTimeout(timer);
      timer = null;
    }
  }
  function runner() {
    lastCall = Date.now();
    lastResult = operation(...lastArgs);
  }
  function throttled(...args) {
    lastArgs = args;
    cancel();
    if (Date.now() - lastCall >= interval) {
      runner();
    } else {
      timer = setTimeout(runner, interval);
    }
    return lastResult;
  }
  throttled.cancel = cancel;
  return throttled;
}

/**
 * Ensures `operation` is only called once every `interval` milliseconds.
 *
 * @param  {number} interval of milliseconds
 * @param  {Function} operation
 * @return {any} the most recent result of `operation`
 */
var _default = exports.default = (0, _curry.default)(throttle);
module.exports = exports.default;