"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _reduce2 = _interopRequireDefault(require("./internal/_reduce"));
/**
 * Transform the contents of `subject` to `into` by applying `operation` to each
 * item.
 *
 * @example
 * reduce(
 *   List(),
 *   (acc, val) => acc.push(val),
 *   Map({ one: 1, two: 2, three: 3 })
 * );
 * // returns List [ 1, 2, 3 ]
 *
 * @param  {any} into
 * @param  {Function} operation
 * @param  {Iterable} subject   [description]
 * @return {Iterable}
 */
var _default = exports.default = (0, _curry.default)(_reduce2.default);
module.exports = exports.default;