"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
function both(condition1, condition2, arg) {
  return !!(condition1(arg) && condition2(arg));
}

/**
 * Returns `true` if the results of `arg` applied to both `condition1` and
 * `condition2` are truthy.
 *
 * @example
 * const isOneToTen = both(
 *   n => n >= 1,
 *   n => n <= 10
 * );
 *
 * isOneToTen(3) === true;
 * isOneToTen(11) === false;
 *
 * @param {Function} condition1
 * @param {Function} condition2
 * @return {boolean}
 */
var _default = exports.default = (0, _curry.default)(both);
module.exports = exports.default;