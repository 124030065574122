"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
const jsToEntrySeq = subject => (0, _immutable.Seq)(subject).entrySeq();
_TransmuteCollection.entrySeq.implement(Array, jsToEntrySeq);
_TransmuteCollection.entrySeq.implementInherited(_immutable.Iterable, subject => subject.entrySeq());
_TransmuteCollection.entrySeq.implement(Object, jsToEntrySeq);
var _default = exports.default = _TransmuteCollection.entrySeq;
module.exports = exports.default;