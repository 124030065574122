"use strict";
'use es6';

/**
 * Creates a function that always returns `returnValue`.
 *
 * @example
 * const alwaysBlue = always('blue');
 * alwaysBlue() === 'blue';
 *
 * @param  {T} returnValue
 * @return {T}
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = always;
function always(returnValue) {
  return () => returnValue;
}
module.exports = exports.default;