"use strict";
'use es6';

/**
 * Returns `true` if `subject` is a JavaScript Number and not `NaN`.
 * 
 * @param {any} value 
 * @return {boolean}
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isNumber;
function isNumber(value) {
  return typeof value === 'number' && !isNaN(value);
}
module.exports = exports.default;