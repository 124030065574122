"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _curry = _interopRequireDefault(require("./curry"));
var _map2 = _interopRequireDefault(require("./internal/_map"));
/**
 * Create a new Iterable by applying `mapper` to each item in `subject`.
 *
 * @example
 * // returns List [ 2, 3, 4 ]
 * map(
 *   (val) => val + 1,
 *   List.of(1, 2, 3)
 * );
 *
 * @param  {Function} mapper applied to each item in `subject`.
 * @param  {Iterable} subject the Iterable to map.
 * @return {Iterable} with each value of `subject` updated with mapper.
 */
var _default = exports.default = (0, _curry.default)(_map2.default);
module.exports = exports.default;