"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = protocol;
var _makeSymbol2 = _interopRequireDefault(require("./internal/_makeSymbol"));
var _setArity2 = _interopRequireDefault(require("./internal/_setArity"));
var _uniqueId = _interopRequireDefault(require("./uniqueId"));
const DISPATCH_TYPE = (0, _makeSymbol2.default)('protocolType');
function getValueKey(id, value) {
  switch (value) {
    case null:
    case undefined:
      return `${value}`;
    default:
      return value.constructor && value.constructor[id] || value[id];
  }
}
function makeKey(id, Type) {
  switch (Type) {
    case null:
      return 'null';
    case undefined:
      return 'undefined';
    default:
      return Type[id] || (0, _uniqueId.default)();
  }
}
function makeKeyInherited(id, Type) {
  switch (Type) {
    case null:
      return 'null';
    case undefined:
      return 'undefined';
    default:
      return Type.prototype.hasOwnProperty(id) ? Type.prototype[id] : (0, _uniqueId.default)();
  }
}
function setKey(subject, id, key) {
  Object.defineProperty(subject, id, {
    configurable: false,
    enumerable: false,
    value: key,
    writable: true
  });
  return subject;
}
function protocol({
  args,
  name,
  fallback
}) {
  const dispatchValueIndex = args.indexOf(DISPATCH_TYPE);
  const id = (0, _makeSymbol2.default)(`__p_${name}`);
  const implementations = {};
  const dispatch = (0, _setArity2.default)(args.length, (...argValues) => {
    const value = argValues[dispatchValueIndex];
    const key = getValueKey(id, value);
    const implementation = key && implementations[key] || fallback;
    if (!implementation) {
      throw new Error(`${name}: not implemented for type \`${value}\``);
    }
    return implementation(...argValues);
  });
  dispatch.implement = (Type, implementation) => {
    const key = makeKey(id, Type);
    if (Type !== undefined && Type !== null && !Type[id]) {
      setKey(Type, id, key);
    }
    implementations[key] = implementation;
    return implementation;
  };
  dispatch.implementInherited = (Type, implementation) => {
    const key = makeKeyInherited(id, Type);
    if (Type !== undefined && Type !== null && !Type.prototype.hasOwnProperty(id)) {
      setKey(Type.prototype, id, key);
    }
    implementations[key] = implementation;
    return implementation;
  };
  return dispatch;
}
protocol.TYPE = DISPATCH_TYPE;
module.exports = exports.default;