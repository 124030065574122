"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reduce2 = _interopRequireDefault(require("./internal/_reduce"));
var _set2 = _interopRequireDefault(require("./internal/_set"));
var _curry = _interopRequireDefault(require("./curry"));
function merge(updates, subject) {
  return (0, _reduce2.default)(subject, (acc, value, key) => (0, _set2.default)(key, value, acc), updates);
}

/**
 * Takes each entry of `updates` and sets it on `subject`.
 *
 * @example
 * // returns Map { "one" => 3, "two" => 2, "three" => 1}
 * merge(
 *   Map({one: 1, two: 2, three: 3}),
 *   Map({one: 3, three: 1})
 * );
 *
 * @param  {Iterable} updates key-value pairs to merge in `subject`.
 * @param  {Iterable} subject the thing to update.
 * @return {Iterable} with each key-value of `updates` merged into `subject`.
 */
var _default = exports.default = (0, _curry.default)(merge);
module.exports = exports.default;