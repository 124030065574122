"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = require("immutable");
var _TransmuteCollection = require("./TransmuteCollection");
_TransmuteCollection.has.implement(Array, (key, arr) => {
  return arr.hasOwnProperty(key);
});
_TransmuteCollection.has.implementInherited(_immutable.Iterable, (key, subject) => subject.has(key));
_TransmuteCollection.has.implement(Object, (key, obj) => {
  return obj.hasOwnProperty(key);
});
var _default = exports.default = _TransmuteCollection.has;
module.exports = exports.default;